import { SearchQueryParams } from '@/components/search/useSearchPageParams';
import { createLocalizationQueryParams } from '@/components/search/utils';
import { DayPassFilterDto } from '@/lib/coworking-day-pass/dto/coworking-day-pass-filter.dto';
import { DateRange } from '@/models/DateRange';
import { DAY_PASS_CUTOFF_HOUR, MAX_BOOKING_MONTHS } from '@/utils/constants';
import {
  addMonths,
  createLocalDay,
  formatToDay,
  isAfter,
  isSameDay,
  isSameWeek,
  isValidDate,
  isWeekEnd,
  setHours,
  setMinutes,
} from '@/utils/date-utils';

import { DayPassFilterType } from './_components/DayPassFilters';
import { LocationDateRangeFilters } from './_components/LocationDateRangeModal';

export function parseDateRange(
  queryParams: SearchQueryParams
): DateRange | undefined {
  const startDay = queryParams.startDay;
  const endDay = queryParams.endDay;

  const today = new Date();
  const todayAtCutoff = setHours(setMinutes(today, 0), DAY_PASS_CUTOFF_HOUR);
  const maxBookingDate = addMonths(today, MAX_BOOKING_MONTHS);

  if (!startDay || !endDay) return undefined;

  if (!isValidDate(startDay) || !isValidDate(endDay)) return undefined;

  if (startDay > endDay) return undefined;

  if (isWeekEnd(startDay) || isWeekEnd(endDay)) return undefined;

  if (!isSameWeek(startDay, endDay)) return undefined;

  if (
    new Date(startDay) > maxBookingDate ||
    new Date(endDay) > maxBookingDate
  ) {
    return undefined;
  }

  if (
    isSameDay(createLocalDay(startDay), today) &&
    isAfter(today, todayAtCutoff)
  ) {
    return undefined;
  }

  return {
    from: new Date(`${startDay}T00:00:00`),
    to: new Date(`${endDay}T00:00:00`),
  };
}

export function mapFilterToDto(filter: DayPassFilterType): DayPassFilterDto {
  const { dateRange } = filter;

  return {
    ...(dateRange && {
      dayRange: {
        startDay: formatToDay(dateRange.from),
        endDay: formatToDay(dateRange.to),
      },
    }),
  };
}

export function createDateRangeQueryParams(dateRange: DateRange | undefined) {
  if (!dateRange)
    return {
      startDay: undefined,
      endDay: undefined,
    };

  return {
    startDay: formatToDay(dateRange.from),
    endDay: formatToDay(dateRange.to),
  };
}

export function getDayPassFilterFromQueryParams(
  queryParams: SearchQueryParams
): DayPassFilterType {
  if (!queryParams) return {};

  return {
    dateRange: parseDateRange(queryParams),
  };
}

export function createDayPassQueryParams(
  filters: LocationDateRangeFilters | undefined
): SearchQueryParams {
  return {
    ...createDateRangeQueryParams(filters?.dateRange),
    ...createLocalizationQueryParams(
      filters?.localization?.coords,
      filters?.localization?.query
    ),
  };
}
